import { Fragment, useState, useContext, useEffect } from 'react';
import { Badge, Checkbox, Input } from 'antd';
import { UserDataActions, UserDataContext } from '../../contexts/UserDataContext';
import EmptyState from '../states/EmptyState';
import { IS_ACTIVE_FIELD_NAME } from '../../constants';
import { DownOutlined, UpOutlined, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';

import classes from './UserDataFilterComponent.module.scss';

const MORE_FILTERS_MIN = 10;

const filterBadgeStyle = {
    width: '16px',
    height: '16px',
    fontSize: '10px',
    lineHeight: '16px',
    backgroundColor: 'rgb(58, 113, 252)'
};

const FilterGroup = ({ data, dispatch, selections = {} }) => {
    const { name, attribute_name, param, values, limit_param, search_param, limit: filters_limit } = data;
    const [expnd, setExpand] = useState(false);
    const [more, setMore] = useState(MORE_FILTERS_MIN);
    const [searchString, setSearchString] = useState('');
    const [visValues, setVisValues] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showLess, setShowLess] = useState(false);
    const [clearSelection, setClearSelection] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);

    const handleToggleExpand = () => {
        if (expnd) {
            setMore(MORE_FILTERS_MIN);
        }
        setExpand(!expnd);
    };

    const handleMore = () => {
        setMore(more + MORE_FILTERS_MIN);
    };

    const handleLess = () => {
        setMore(more - MORE_FILTERS_MIN);
    };

    const selectUserDataFilter = (name, value, checked) => {
        dispatch({ type: UserDataActions.SELECT, payload: { name, value, checked } });
    };

    const handleSearchString = (event) => {
        dispatch({ type: UserDataActions.FILTER_SEARCHES, payload: { name: search_param, value: event.target.value } });
        setSearchString(event.target.value);
    };

    const handleClearSelection = () => {
        dispatch({ type: UserDataActions.RESET_SELECT_BY_NAME, payload: { name: param } });
    };

    const handleClearSearch = () => {
        dispatch({ type: UserDataActions.FILTER_SEARCHES, payload: { name: search_param, value: '' } });
        setSearchString('');
    };

    useEffect(() => {
        setShowMore(values.length > more);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (values) {
            let sortedValuesBySelection = values;

            const currentSelectedCount = Object.values(selections).reduce((a, v) => {
                return a + (v ? 1 : 0);
            }, 0);

            setSelectedCount(currentSelectedCount);

            setClearSelection(!!currentSelectedCount);

            sortedValuesBySelection.sort((a, b) => {
                const sort1 = !!selections[b.value] - !!selections[a.value];
                const sort2 = (a.label || '').localeCompare(b.label || '');
                return sort1 == 0 ? sort2 : sort1;
            });

            if (more) {
                dispatch({ type: UserDataActions.FILTER_LIMITS, payload: { name: limit_param, value: more } });
            }

            setVisValues(sortedValuesBySelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, more, searchString]);

    useEffect(() => {
        if (!visValues.length || searchString) {
            setShowMore(false);
            setShowLess(false);
        } else {
            setShowLess(more > MORE_FILTERS_MIN);
            setShowMore(values.length > filters_limit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visValues, searchString, values]);

    return (
        <div>
            <div
                className="flex "
                style={{
                    padding: '8px 20px',
                    cursor: 'pointer'
                }}
                onClick={handleToggleExpand}>
                <span className="flex-1">
                    <span className={classes.filterTitle} style={{ fontWeight: selectedCount > 0 ? 800 : 400 }}>
                        {name}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    {selectedCount > 0 ? (
                        <span className={classes.filterNumber}>
                            ({selectedCount}/{values.length})
                        </span>
                    ) : null}
                </span>
                <span className="text-right">
                    {expnd ? (
                        <UpOutlined style={{ color: '#BFBFBF' }} />
                    ) : (
                        <DownOutlined style={{ color: '#BFBFBF' }} />
                    )}
                </span>
            </div>
            {expnd ? (
                <div
                    style={{
                        padding: '2px 20px 8px'
                    }}>
                    <Input
                        suffix={
                            searchString ? (
                                <CloseCircleFilled color="#8C8C8C" onClick={handleClearSearch} />
                            ) : (
                                <SearchOutlined color="#8C8C8C" />
                            )
                        }
                        placeholder="Search"
                        value={searchString}
                        onChange={handleSearchString}
                    />
                    {clearSelection ? (
                        <span
                            className="text-link"
                            onClick={handleClearSelection}
                            style={{
                                display: 'inline-block',
                                flexBasis: '50%',
                                fontSize: '80%',
                                cursor: 'pointer',
                                padding: '8px 0 2px'
                            }}>
                            Clear Selection
                        </span>
                    ) : null}
                    {visValues.map(({ label, value, count, total }, j) => {
                        const isSelected = selections && selections[value];
                        let checkboxLabel = label;
                        if (attribute_name === IS_ACTIVE_FIELD_NAME) {
                            if (typeof checkboxLabel === 'boolean') {
                                checkboxLabel = checkboxLabel ? 'No' : 'Yes';
                            } else if (checkboxLabel === 'true' || checkboxLabel === 'false') {
                                checkboxLabel = checkboxLabel === 'true' ? 'No' : 'Yes';
                            }
                        } else {
                            if (checkboxLabel === null) {
                                checkboxLabel = '(Blank)';
                            } else if (typeof checkboxLabel === 'boolean') {
                                checkboxLabel = checkboxLabel ? 'Yes' : 'No';
                            } else if (checkboxLabel === 'true' || checkboxLabel === 'false') {
                                checkboxLabel = checkboxLabel === 'true' ? 'Yes' : 'No';
                            }
                        }

                        return (
                            <div
                                style={{
                                    padding: '6px 0'
                                }}
                                key={j}>
                                <Checkbox
                                    className={
                                        count === 0 || total === 0 ? classes.filterCheckBoxGray : classes.filterCheckBox
                                    }
                                    checked={isSelected}
                                    onChange={(e) => {
                                        selectUserDataFilter(param, value, !isSelected);
                                    }}>
                                    {checkboxLabel}&nbsp;&nbsp;
                                    <span className={classes.filterNumber} style={{ fontStyle: 'italic' }}>
                                        ({count}/{total})
                                    </span>
                                </Checkbox>
                            </div>
                        );
                    })}
                    <div className="flex">
                        {showMore ? (
                            <span
                                className="text-link"
                                onClick={handleMore}
                                style={{
                                    display: 'inline-block',
                                    flexBasis: '50%',
                                    fontSize: '80%',
                                    cursor: 'pointer',
                                    padding: '4px 0 8px'
                                }}>
                                Show More
                            </span>
                        ) : null}
                        {showLess ? (
                            <span
                                className={`text-link ${showMore ? 'text-right' : 'text-left'}`}
                                onClick={handleLess}
                                style={{
                                    display: 'inline-block',
                                    flexBasis: '50%',
                                    fontSize: '80%',
                                    cursor: 'pointer',
                                    padding: '4px 0 8px'
                                }}>
                                Show Less
                            </span>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const UserDataFilterComponent = ({ colapsed = false }) => {
    const [state, dispatch] = useContext(UserDataContext);
    const { filters, selected } = state;

    let selectedCount = 0;

    Object.values(selected).forEach((f) => {
        Object.values(f).forEach((v) => {
            selectedCount += v ? 1 : 0;
        });
    });

    const resetSelection = () => {
        dispatch({ type: UserDataActions.RESET_SELECT });
    };

    return (
        <div>
            <div
                className="flex w-full"
                style={{
                    padding: colapsed ? '33px 0 0' : '8px 20px 8px 50px'
                }}>
                <span className="flex-1">
                    <span className="font-bold text-black" style={{ fontSize: '16px', lineHeight: '20px' }}>
                        Filter
                    </span>
                    {!colapsed && <>&nbsp;&nbsp;&nbsp;</>}
                    {colapsed && <br />}
                    <Badge count={selectedCount} style={filterBadgeStyle} />
                </span>
                {!colapsed && selectedCount > 0 && (
                    <button onClick={resetSelection} className="flex-1 text-link text-right">
                        Reset
                    </button>
                )}
            </div>
            {colapsed ? null : (
                <div style={{ overflow: 'auto' }}>
                    <hr />
                    {!!filters.length ? (
                        filters.map((f, i) => (
                            <Fragment key={i}>
                                <FilterGroup data={f} dispatch={dispatch} selections={selected[f.param]} />
                                <hr />
                            </Fragment>
                        ))
                    ) : (
                        <EmptyState />
                    )}
                </div>
            )}
        </div>
    );
};

export default UserDataFilterComponent;
