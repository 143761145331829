import React from 'react';
import setClassNames from 'classnames';
import classes from './UserCard.module.scss';

function UserCard({ user, sidebarOpen }) {
    const getInitials = (user) => {
        return `${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`;
    };

    return (
        <div className={setClassNames(classes.UserCard, { [classes.UserCardSmall]: false })}>
            <div className={setClassNames(classes.UserCircle, { [classes.UserCircleSmall]: false })}>
                <label>{getInitials(user)}</label>
            </div>
            {sidebarOpen ? (
                <div className={classes.UserInfo}>
                    <h2 className={classes.Name}>{user.fullName}</h2>
                    <h3 className={classes.Rank}>{user.rank.name}</h3>
                    <h4 className={classes.Department}>{user.agency.name}</h4>
                </div>
            ) : null}
        </div>
    );
}

export default UserCard;
