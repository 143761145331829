import React, { useContext, useMemo, useState } from 'react';
import setClassNames from 'classnames';
import classes from './SideBar.module.scss';
import Logo from '../../../assets/images/benchmark-logo.svg';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import Navigation from '../navigation/Navigation';
import UserCard from '../user-card/UserCard';
import { TokenContext } from '../../../contexts/TokenContext';
import { useStatsApi } from '../../../hooks/useApi';
import config from '../../../config.json';
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { Logout } from '../navigation/LinksMarkup';

const LEGACY_ROOT = config.bmsUrl;

function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { decodedToken } = useContext(TokenContext);
    const [userData] = useStatsApi('dashboard/username');
    const currentUser = useMemo(
        () => ({
            fullName: userData ? userData.first_name + ' ' + userData.last_name : decodedToken.user_name,
            firstName: userData?.first_name || '',
            lastName: userData?.last_name || '',
            rank: {
                name: userData?.rank || ''
            },
            agency: {
                name: decodedToken.agency.name
            }
        }),
        [decodedToken, userData]
    );

    const handleToggleSidebar = () => {
        setSidebarOpen((toggle) => !toggle);
    };

    return (
        <aside className={setClassNames(classes.SidebarAside, { [classes.SidebarAsideClosed]: !sidebarOpen })}>
            <UserCard user={currentUser} sidebarOpen={sidebarOpen} />
            <div className={classes.Divider} />
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/reports?create_report=true`}>
                <div className={classes.NewReportBox} style={{paddingLeft: sidebarOpen ? '32px' : '16px'}}>
                    <Button type="primary" shape="round" icon={<PlusOutlined />} size="large">{sidebarOpen && 'New Report'}</Button>
                    {/* <i className="bdm-icon-new-report bdm-icon" /> */}
                    {/* <span data-sidebar-text>New Report</span> */}
                </div>
            </a>
            {/* <div className={classes.Divider} /> */}
            <div className={classes.NavBox}>
                <Navigation permissions={decodedToken.authorities} sidebarOpen={sidebarOpen} />
            </div>
            <div className={classes.Divider} />
            <div className={classes.NavigationLink}>
                <div className={classes.LogoutBox}>
                    <Logout/>
                    {/* <a className={classes.NavigationLink} onClick={()=>{}}>
                        <i className={`bdm-icon-log-out bdm-icon ${classes.Icon}`} />
                        <span data-sidebar-text>Log Out</span>
                    </a> */}
                    {/* <Button type="primary" shape="round" icon={<PlusOutlined/>} style={{ fontSize: '16px', color: '#ffffff', backgroundColor: '#0040dd',  borderColor: '#0040dd'}} size="large">{sidebarOpen && 'New Report'}</Button> */}
                    {/* <i className="bdm-icon-new-report bdm-icon" /> */}
                    {/* <span data-sidebar-text>New Report</span> */}
                </div>
            </div>
            <div className={classes.Divider} />
            <button
                className={setClassNames(classes.SidebarButton, { [classes.SidebarButtonCentered]: !sidebarOpen })}
                onClick={handleToggleSidebar}>
                {sidebarOpen ? (
                    <>
                        <img src={Logo} alt="Benchmark" />
                        <DoubleLeftOutlined className={classes.CollapseIcon} />
                    </>
                ) : (
                    <DoubleRightOutlined className={classes.CollapseIcon} />
                )}
            </button>
            <div className={classes.SideFooter}>
                {sidebarOpen ? (
                    <>
                        <a href="https://benchmarkanalytics.com/contact">Customer Support</a>
                        <div className={classes.CopyRight}>
                        <div >© 2024 Benchmark Analytics</div>
                        <div >All Rights Reserved</div>
                        </div>
                    </>
                ) : null}
            </div>
        </aside>
    );
}

export default Sidebar;
