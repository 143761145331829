import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    StarOutlined,
    KeyOutlined,
    LogoutOutlined,
    UserOutlined,
    SolutionOutlined,
    DesktopOutlined,
    ProfileOutlined,
    DotChartOutlined,
    TeamOutlined,
    HomeOutlined
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import classes from './Navigation.module.scss';
import config from '../../../config.json';
import { TokenContext } from '../../../contexts/TokenContext';
import getLmsUrl from '../../../utilities';
import { AUTHENTICATION_KEY, FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH } from '../../../constants';

const LEGACY_ROOT = config.bmsUrl;

const MyProfile = () => {
    const { decodedToken } = useContext(TokenContext);

    const linkToMyProfile = `${LEGACY_ROOT}/agency/${decodedToken.agencyId}/user-profile/${decodedToken.userId}`;

    return (
        <li>
            <a className={classes.NavigationLink} href={linkToMyProfile}>
                <UserOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>My Profile</span>
            </a>
        </li>
    );
};

export const Logout = (props) => {
    const { decodedToken, token } = useContext(TokenContext);
    const { client_id, scope } = decodedToken;

    const onLogout = () => {
        localStorage.removeItem(AUTHENTICATION_KEY);
        const sScope = Array.isArray(scope) ? scope.join('+') : scope;
        window.location = `${config.ssoLogoutUrl}?token=${token}&sso_logout_uri=${config.securityServiceUrl}/logout&client_id=${client_id}&scope=${sScope}`;
    };

    return (
        <a className={classes.NavigationLink} style={{ paddingLeft: 12 }} onClick={onLogout}>
            <LogoutOutlined className={`${classes.Icon}`} style={{ color: 'red' }} />
            {/* <i className={`bdm-icon-log-out ${classes.LogoutIcon}`} color='red' /> */}
            <span data-sidebar-text>Log Out</span>
        </a>
    );
};

export const links = {
    home: (
        <li key="home">
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}`}>
                <HomeOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>Home</span>
            </a>
        </li>
    ),
    people: (
        <li key="people">
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/people`}>
                <TeamOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>People</span>
            </a>
        </li>
    ),
    peopleOu: (
        <li key="people">
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/people-ou`}>
                <TeamOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>People</span>
            </a>
        </li>
    ),
    'my profile': <MyProfile key="myprofile" />,
    analytics: (locationPathname) => {
        const nonAnalyticsPaths = [FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH, 'internal_affairs'];
        const maybeActiveClass = !nonAnalyticsPaths.some((path) => locationPathname.includes(path))
            ? classes.Active
            : null;
        return (
            <li key="analytics">
                <NavLink className={`${classes.NavigationLink} ${maybeActiveClass}`} to="/">
                    <DotChartOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                    <span data-sidebar-text>Analytics</span>
                </NavLink>
            </li>
        );
    },
    firstSign: (locationPathname) => {
        const maybeActiveClass = locationPathname.includes(FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH)
            ? classes.Active
            : null;
        return (
            <li key="firstSign">
                <NavLink
                    className={`${classes.NavigationLink} ${maybeActiveClass}`}
                    to={`/${FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH}`}>
                    <StarOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                    <span data-sidebar-text>First Sign</span>
                </NavLink>
            </li>
        );
    },
    reports: (
        <li key="reports">
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/reports`}>
                <ProfileOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>Reports</span>
            </a>
        </li>
    ),
    training: (
        <li key="training">
            <a className={classes.NavigationLink} href={getLmsUrl()}>
                <DesktopOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>Training</span>
            </a>
        </li>
    ),
    'internal affairs': (locationPathname) => {
        const maybeActiveClass = locationPathname.includes('internal_affairs') ? classes.Active : null;
        return (
            <li key="internalaffairs">
                <NavLink className={`${classes.NavigationLink} ${maybeActiveClass}`} to="/internal_affairs">
                    <SolutionOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                    <span data-sidebar-text>Internal Affairs</span>
                </NavLink>
            </li>
        );
    },

    // admin: (
    //     <li key="admin">
    //         <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/administrator/agencies/1`}>
    //             <i className={`bdm-icon-admin bdm-icon ${classes.Icon}`} />
    //             <span data-sidebar-text>Admin</span>
    //         </a>
    //     </li>
    // ),

    admin: (
        <li key="admin">
            <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/administrator/agencies/1`}>
                <KeyOutlined className={`${classes.Icon}`} style={{ color: '#6ed960' }} />
                <span data-sidebar-text>Admin</span>
            </a>
        </li>
    )
    // settings: (
    //     <li key="settings">
    //         <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/settings`}>
    //             <i className={`bdm-icon-settings bdm-icon ${classes.Icon}`} />
    //             <span data-sidebar-text>Settings</span>
    //         </a>
    //     </li>
    // ),
    // logout: <Logout key="logout" />
};

export const statsLinks = {
    accessStatsDesigner: (
        <li key="accessStatsDesigner">
            <NavLink to="/report-builder" className={classes.StatsLink}>
                <FontAwesomeIcon className={classes.StatsIcon} icon={['fas', 'pencil-ruler']} />
                <span data-sidebar-text>Report Designer</span>
            </NavLink>
        </li>
    ),
    accessStatsAdmin: (
        <li key="accessStatsAdmin">
            <NavLink to="/report-builder-admin" className={classes.StatsLink}>
                <FontAwesomeIcon className={classes.StatsIcon} icon={['fas', 'stamp']} />
                <span data-sidebar-text>Designer Admin</span>
            </NavLink>
        </li>
    ),
    accessStatsReports: (
        <li key="accessStatsReports">
            <NavLink to="/canned-reports" className={classes.StatsLink}>
                <FontAwesomeIcon className={classes.StatsIcon} icon={['fas', 'layer-group']} />
                <span data-sidebar-text>Canned Reports</span>
            </NavLink>
        </li>
    )
};
