import React, { useEffect, useMemo, useState } from 'react';
import { copy, jsonSortFunction, stripIrrelevantProperties } from './utilities';
import useDebounce from './hooks/useDebounce';
import Sidebar from '../components/layout/sidebar/Sidebar';
import { FiltersProvider } from '../contexts/FiltersContext';
import { useStatsApi, useStatsPostApi } from '../hooks/useApi';
import EditorContext from '../contexts/EditorContext';
import LoadingState from '../components/states/LoadingState';
import ErrorState from '../components/states/ErrorState';
import ConfigurationRouteMapper from '../components/layout/main-layout/ConfigurationRouteMapper';
import ComponentTitle from './components/ComponentTitle';
import CodeButton from './components/CodeButton';

import AddNewComponentModal from './components/AddNewComponentModal';
import { ComponentsSupportingChildren, Visualizations } from '../utilities/visualization-lookup';

import EditComponentModal from './components/EditComponentModal';

import ComponentValidator from './components/ComponentValidator';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Draggable from 'react-draggable';

import ResizePanel from 'react-resize-panel';

import { Select, Button, Checkbox, Modal, Tree } from 'antd';
import { useHistory, useLocation, BrowserRouter as Router } from 'react-router-dom';
import _ from 'lodash';
import { PDFProgressProvider } from '../contexts/PDFProgressContext';
const Option = Select.Option;

const MainLayout = (props) => {
    const [modifiedPages, setModifiedPages] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showCodeEditorIcon, setShowCodeEditorIcon] = useState(true);
    const [selectedPageFilter, setSelectedPageFilter] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [modules, setModules] = useState([]);
    const [allPages, setAllPages] = useState([]);
    const [overriddenPages, setOverriddenPages] = useState([]);
    const [nonModulePages, setNonModulePages] = useState([]);

    const [editComponent, setEditComponent] = useState(undefined);
    const [openEditor, setOpenEditor] = useState(null);
    const [editComponentModalOpen, setEditComponentModalOpen] = useState(false);
    const [editStack, setEditStack] = useState([]);
    const [commitInstructions, setCommitInstructions] = useState([]);
    const [isCommitting, setIsCommitting] = useState(false);

    const [editorJson, setEditorJson] = useState({});
    const [editorVisible, setEditorVisible] = useState(false);
    const [bounds, setBounds] = useState([]);

    // For new component modal:
    const [components, setComponents] = useState([]);
    const [newComponentModalOpen, setNewComponentModalOpen] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [tempIdCounter, setTempIdCounter] = useState(-1);

    const [showSidebar, setShowSidebar] = useState([]);

    const [reloadCount, setReloadCount] = useState(0);
    const reloadFilter = useMemo(() => {
        return { reloadCount };
    }, [reloadCount]);
    const doReload = () => {
        setReloadCount(reloadCount + 1);
    };
    // const [pages, loading, error] = useStatsApi('dashboard/pages', reloadFilter);

    const [modifyComponentResults, modifyComponentLoading, modifyComponentError] = useStatsPostApi(
        'dashboard/component/batch',
        {},
        commitInstructions
    );
    const pages = modifyComponentResults;
    const loading = modifyComponentLoading;
    const error = modifyComponentError;

    useEffect(() => {
        console.log({ commitInstructions, modifyComponentResults, modifyComponentLoading, modifyComponentError });
    }, [commitInstructions, modifyComponentResults, modifyComponentLoading, modifyComponentError]);

    useEffect(() => {
        if (
            commitInstructions.length &&
            editStack.length &&
            modifyComponentResults &&
            !modifyComponentLoading &&
            !modifyComponentError
        ) {
            setEditStack([]);
            setCommitInstructions([]);
        }
    }, [modifyComponentLoading]);

    const history = useHistory();
    const location = useLocation();

    const pushEdit = (edit) => {
        setEditStack([...editStack, edit]);
    };

    const popEdit = () => {
        setEditStack(editStack.slice(0, -1));
    };

    const pokeEdit = (edit) => {
        setEditStack([...editStack.slice(0, -1), { ...editStack[editStack.length - 1], ...edit }]);
    };

    const onCodeSelected = (component) => {
        setOpenEditor(component);
    };

    const onOpenAddNewComponent = () => {
        const parentPath = selectedKey.split('-');
        const parentComponent = parentPath.reduce(
            (accumulator, keyPath, index) => {
                return accumulator && accumulator.children && accumulator.children[parseInt(keyPath)];
            },
            { children: treeData }
        );
        setEditComponent(parentComponent);
        setNewComponentModalOpen(true);
    };

    const onNewPage = () => {
        const newPageId = tempIdCounter;
        setTempIdCounter(tempIdCounter - 1);
        pushEdit({
            op: 'newPage',
            cmp: {
                pageId: newPageId,
                title: 'New Page ' + Math.abs(newPageId),
                filters: [],
                path: '/new_page/' + Math.abs(newPageId),
                order: 0,
                agencyId: 0,
                children: []
            }
        });
    };

    const getJsonExport = () => {
        const pages = allPages.map(stripIrrelevantProperties).filter((page) => !!page);
        pages.sort(jsonSortFunction);

        return {
            generatedBy: 'PAGE_EDITOR',
            pageConfigurations: pages
        };
    };

    const copyJson = () => {
        copy(JSON.stringify(getJsonExport(), null, 2));
    };

    const editor = {
        setEditorVisible,
        showCodeEditorIcon,
        setEditComponentModalOpen,
        editComponent,
        setEditComponent,
        doReload,
        onCodeSelected
    };

    const expandAllSubcomponents = (component) => {
        const getKeys = (subcomponent) => {
            if (!subcomponent) return [];
            return _.flattenDeep([subcomponent.key].concat(_.map(subcomponent.children || [], getKeys)));
        };
        addExpandedKeys(getKeys(component));
    };

    const addExpandedKeys = (keys) => {
        setExpandedKeys((expandedKeys) => {
            return [...new Set([...expandedKeys, ...keys])];
        });
    };

    const mapPages = (prefix) => (page, pageKey) => {
        const children = page.children && page.children.map(mapPages((prefix || '') + pageKey + '-'));
        return {
            ...page,
            children,
            order: page.order || pageKey,
            key: (prefix || '') + pageKey,
            title: page.title || ComponentTitle({ onCodeSelected }),
            icon: !!page.path && (
                <div className="flex my-1">
                    <CodeButton
                        onClick={() => {
                            onCodeSelected(page);
                        }}
                        component={{ ...page, key: pageKey }}
                    />
                </div>
            )
        };
    };

    const currentPath = location.pathname;

    const editStackSchema = {
        op: 'updateComponent',
        cmp: {
            pageId: 'pageId',
            parentPage: 'parentPage',
            componentId: 'componentId'
        }
    };

    const reduceEditStackOverrides = (pagesAccumulator, edit, index) => {
        const mapEdit = (component) => {
            switch (edit.op) {
                case 'newPage':
                    return component;
                case 'newComponent':
                    const newComponent = edit.cmp;
                    if (!newComponent) {
                        return component;
                    }
                    if (
                        // First, if parent is the page itself
                        (newComponent.parentPage == component.pageId && newComponent.parentComponent == null) ||
                        // Then, if the parent is another component
                        (newComponent.parentPage == component.parentPage &&
                            newComponent.parentComponent == component.componentId)
                    ) {
                        let newComponentInsertion = {
                            ...component,
                            children: [...(component.children || []), newComponent]
                        };
                        return newComponentInsertion;
                    } else {
                        return {
                            ...component,
                            children: _.map(component.children, mapEdit)
                        };
                    }
                    break;
                case 'updatePage':
                case 'updateComponent':
                    const editComponent = edit.cmp;
                    if (!editComponent) {
                        return component;
                    }
                    if (
                        component.pageId == editComponent.pageId &&
                        component.parentPage == editComponent.parentPage &&
                        component.componentId == editComponent.componentId
                    ) {
                        return editComponent;
                    } else {
                        return {
                            ...component,
                            children: _.map(component.children, mapEdit)
                        };
                    }
                    break;
                case 'deletePage':
                case 'deleteComponent':
                    const deleteComponent = edit.cmp;
                    if (!deleteComponent) {
                        return component;
                    }
                    if (
                        component.pageId == deleteComponent.pageId &&
                        component.parentPage == deleteComponent.parentPage &&
                        component.componentId == deleteComponent.componentId
                    ) {
                        return null;
                    } else {
                        return {
                            ...component,
                            children: _.map(component.children, mapEdit).filter((cmp) => {
                                return !!cmp;
                            })
                        };
                    }
                    break;
                default:
                    throw 'edit type not supported: ' + edit.op;
            }
        };
        if (edit.op == 'newPage') {
            return [...pagesAccumulator, edit.cmp];
        }
        // apply the edit operation to each page and all its children in the tree
        return pagesAccumulator.map(mapEdit).filter((cmp) => {
            return !!cmp;
        });
    };

    const sortRecursive = (cmp) => {
        if (!cmp.children || !cmp.children.length) {
            return cmp;
        }
        return {
            ...cmp,
            children: cmp.children.map(sortRecursive).sort((a, b) => {
                return (a.order || 0) - (b.order || 0);
            })
        };
    };

    useEffect(() => {
        if (openEditor) {
            setEditComponent(openEditor);
            pushEdit({
                op: openEditor.path ? 'updatePage' : 'updateComponent',
                cmp: openEditor
            });
            setEditComponentModalOpen(true);
            setOpenEditor(null);
        }
    }, [openEditor]);

    useEffect(() => {
        if (!pages) {
            return [];
        }
        // apply the edit stack to the pages
        const calcOverriddenPages = editStack.reduce(reduceEditStackOverrides, pages).map(sortRecursive);
        setOverriddenPages(calcOverriddenPages);
        const modules = calcOverriddenPages.filter((page) => !!page.module);
        const nonModulePages = calcOverriddenPages.filter((page) => !page.module);
        const thisPage = calcOverriddenPages.filter((page) => page.path.split(':')[0] == history?.location?.pathname);
        setAllPages(calcOverriddenPages);
        setModules(modules);
        setNonModulePages(nonModulePages);
        if (selectedPageFilter == 'pages') {
            setTreeData(nonModulePages);
        } else if (selectedPageFilter == 'modules') {
            setTreeData(modules);
        } else if (selectedPageFilter == 'all_pages') {
            setTreeData(calcOverriddenPages);
        } else if (selectedPageFilter == 'this_page') {
            setTreeData(thisPage);
            expandAllSubcomponents(thisPage[0]);
        } else {
            setTreeData(thisPage);
            expandAllSubcomponents(thisPage[0]);
        }

        const components = [];
        overriddenPages.forEach((page) => {
            page.children
                .map((child) => ({ belongsToPage: page.title, ...child }))
                .forEach((comp) => components.push(comp));
        });
        setComponents(components);
    }, [pages, selectedPageFilter, editStack, currentPath]);

    const displayedTreeData = treeData.map(mapPages());

    const debouncedPageEdits = useDebounce(overriddenPages, 500);

    const pageOptions = useMemo(
        () =>
            overriddenPages
                ? overriddenPages.map(({ key, title }) => ({
                      key,
                      title
                  }))
                : [],
        [overriddenPages]
    );
    const componentOptions = useMemo(
        () =>
            Object.keys(Visualizations)
                .sort()
                .map((key) => ({ key, title: key })),
        []
    );
    const componentLibraryOptions = useMemo(
        () =>
            components &&
            components.map((component, index) => ({
                key: index,
                title: `${component.belongsToPage} -> ${component.component} ${
                    component.params && component.params.title ? ` -> "${component.params.title}"` : ''
                }`
            })),
        [components]
    );

    const getNextTempId = () => {
        setTempIdCounter(tempIdCounter - 1);
        return tempIdCounter;
    };

    if (loading || modifyComponentLoading) {
        return <LoadingState />;
    }

    if (error) {
        return (
            <ErrorState
                encompassPage={true}
                title="Failed"
                text="We had an issue communicating with one of our services, please try again."
            />
        );
    }

    const onSelect = (keys) => {
        const firstKey = keys[0];
        setSelectedKey(firstKey);
    };

    return (
        <PDFProgressProvider>
            <FiltersProvider>
                <EditorContext.Provider value={editor}>
                    <div className="min-w-full flex flex-row">
                        {newComponentModalOpen && (
                            <AddNewComponentModal
                                modifiedPages={overriddenPages}
                                componentLibraryOptions={componentLibraryOptions}
                                componentOptions={componentOptions}
                                newComponentModalOpen={newComponentModalOpen}
                                pageOptions={pageOptions}
                                editComponent={editComponent}
                                setNewComponentModalOpen={setNewComponentModalOpen}
                                pushEdit={pushEdit}
                                getNextTempId={getNextTempId}
                                components={components}
                            />
                        )}
                        {editComponentModalOpen && (
                            <EditComponentModal
                                setEditComponentModalOpen={setEditComponentModalOpen}
                                editComponentModalOpen={editComponentModalOpen}
                                setEditComponent={setEditComponent}
                                component={editComponent}
                                pushEdit={pushEdit}
                                popEdit={popEdit}
                                pokeEdit={pokeEdit}
                                allPages={allPages}
                            />
                        )}
                        <ResizePanel direction="e" style={{ width: 500 }}>
                            <div className="overflow-auto p-2 ">
                                <div className="rounded-xs-sm border border-inactive-tab mb-2 p-2 ">
                                    <div className="flex space-x-2 items-center flex-wrap">
                                        <Button onClick={copyJson}>Copy JSON</Button>
                                        <Button type={editStack.length && 'primary'} onClick={popEdit}>
                                            Undo
                                        </Button>
                                        <Button
                                            type={editStack.length && 'primary'}
                                            onClick={() => {
                                                setCommitInstructions(editStack);
                                                console.log({ editStack });
                                            }}>
                                            Commit ({editStack.length})
                                        </Button>
                                    </div>
                                    <div className="flex space-x-2 items-center flex-wrap">
                                        <Button type="primary" onClick={onNewPage}>
                                            New Page
                                        </Button>
                                        {selectedKey && (
                                            <Button type={'primary'} onClick={onOpenAddNewComponent}>
                                                New Component
                                            </Button>
                                        )}
                                    </div>
                                    <div className="mt-3 flex space-x-2 items-center">
                                        <Checkbox checked={showSidebar} onChange={() => setShowSidebar(!showSidebar)}>
                                            Show Sidebar
                                        </Checkbox>
                                        <Checkbox
                                            checked={showCodeEditorIcon}
                                            onChange={() => setShowCodeEditorIcon(!showCodeEditorIcon)}>
                                            Enable Code Icon
                                        </Checkbox>
                                    </div>
                                    <div className="flex space-x-2 items-center">
                                        <Select
                                            defaultValue="this_page"
                                            onChange={(value) => {
                                                setSelectedPageFilter(value);
                                            }}>
                                            <Option value="modules">Modules</Option>
                                            <Option value="pages">Pages</Option>
                                            <Option value="this_page">This Page</Option>
                                            <Option value="all_pages">All Pages</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="rounded-xs-sm border border-inactive-tab p-2">
                                    <Tree
                                        showIcon={true}
                                        className="draggable-tree"
                                        blockNode
                                        treeData={displayedTreeData}
                                        onSelect={onSelect}
                                    />
                                </div>
                            </div>
                        </ResizePanel>

                        {showSidebar && <Sidebar />}
                        <div className="flex-1 flex flex-col">
                            <FiltersProvider>
                                <ConfigurationRouteMapper modules={modules} pages={debouncedPageEdits} />
                            </FiltersProvider>
                        </div>
                    </div>
                </EditorContext.Provider>
            </FiltersProvider>
        </PDFProgressProvider>
    );
};

export default MainLayout;
